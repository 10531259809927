import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <body >
       <main className="main-container">
    <section className="info-section">
     <h1 className="logo">Khaware</h1>
      <div className="text-section">
        <h1><span>We're</span> coming soon</h1>
        <p> Hello fellow shoppers! We're currently building our new fashion store.
         </p>
         
    </div>
    </section>
    <section className="img-section">
    </section>
  </main>
    </body>
  );
}

export default App;
